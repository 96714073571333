import _ from "lodash";
import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FeaturePost from "../../components/feature-post/feature-post";
import { StaticImage } from "gatsby-plugin-image";
import PromotionImage from "../../images/ad.png";
// import useInstagram from "../../hooks/use-instagram";
import {
	SidebarWrapper,
	SidebarWidget,
	WidgetTitle,
	TagItem,
	InstagramWrapper,
	InstagramPhoto,
} from "./style";

type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
	// const instagramPhotos = useInstagram();
	const Data = useStaticQuery(graphql`
		query {
			TAGS: allWpPost(sort: { fields: date, order: DESC }) {
				group(field: tags___nodes___name) {
					fieldValue
					totalCount
				}
			}
			POSTS: allWpPost(sort: { fields: date, order: DESC }, limit: 4) {
				edges {
					node {
						excerpt
						uri
						date(formatString: "YYYY年MM月DD日")
						title
						excerpt
						tags {
							nodes {
								name
							}
						}
						featuredImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											quality: 100
											sizes: "400"
											placeholder: BLURRED
											layout: CONSTRAINED
											formats: [AUTO, WEBP, AVIF]
										)
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	// const Posts = Data.allMarkdownRemark.edges;
	const Tags = Data.TAGS.group;
	const Posts = Data.POSTS.edges;
	// console.log("======================");
	// console.dir(Tags);
	// console.dir(Posts);
	return (
		<SidebarWrapper>
			{/* <SidebarWidget>
				<WidgetTitle>Promotion</WidgetTitle>
				<a
					href="https://1.envato.market/r1jdv"
					aria-label="Get StoryHub"
					target="_blank"
				>
					<img src={PromotionImage} alt="Get StoryHub" />
				</a>
			</SidebarWidget> */}
			<SidebarWidget>
				<WidgetTitle>この記事を書いた人</WidgetTitle>
				<div className="author-box">
					<div className="author">
						<StaticImage
							src="../../images/avatar.png" // 相対パス
							width={100}
							alt="avatar"
							className="avatar"
							placeholder="blurred" // ボヤッと読み込ませるオプション。他にもいくつかある
						/>
						<div className="name">宅配クリーニング博士</div>
					</div>

					<div className="author-detail">
						<p className="">
							宅配クリーニング博士です。
							<br />
							確かな情報を持っている有識者さんからの情報提供をもとに作っています。
							<br />
							負担から解放する家事の時短ハックメディアを運営し、年間500時間を節約する情報を発信中です。
						</p>
					</div>
				</div>
			</SidebarWidget>
			<SidebarWidget>
				{/* <WidgetTitle>よく読まれている記事</WidgetTitle>
				{Posts.map(({ node }: any) => {
					const tags = node.tags.nodes.map((tag: any) => {
						return tag.name;
					});
					const title = node.title;
					// Random Placeholder Color
					const placeholderColors = [
						"#55efc4",
						"#81ecec",
						"#74b9ff",
						"#a29bfe",
						"#ffeaa7",
						"#fab1a0",
						"#e17055",
						"#0984e3",
						"#badc58",
						"#c7ecee",
					];
					const setColor =
						placeholderColors[
							Math.floor(Math.random() * placeholderColors.length)
						];

					// return (
					// 	<FeaturePost
					// 		key={node.uri}
					// 		title={title}
					// 		image={
					// 			node.featuredImage == null
					// 				? null
					// 				: node.featuredImage.node.localFile
					// 						.childImageSharp.gatsbyImageData
					// 		}
					// 		url={node.uri}
					// 		tags={tags}
					// 		date={node.date}
					// 		placeholderBG={setColor}
					// 	/>
					// );
				})} */}
			</SidebarWidget>
			<SidebarWidget>
				<WidgetTitle>カテゴリの一覧</WidgetTitle>
				{Tags.map((tag: any) => (
					<TagItem key={tag.fieldValue}>
						<span>#</span>
						<Link to={`/tags/${_.kebabCase(tag.fieldValue)}/`}>
							{tag.fieldValue} <span>({tag.totalCount})</span>
						</Link>
					</TagItem>
				))}
			</SidebarWidget>
			{/* Fix インスタはあとで適用importもあわせて */}
			<SidebarWidget>
				{/* <WidgetTitle>Instagram</WidgetTitle>
				{instagramPhotos ? (
					<InstagramWrapper>
						{instagramPhotos.slice(0, 4).map((node: any) => {
							// Random Placeholder Color
							const placeholderColors = [
								"#55efc4",
								"#81ecec",
								"#74b9ff",
								"#a29bfe",
								"#ffeaa7",
								"#fab1a0",
								"#e17055",
								"#0984e3",
								"#badc58",
								"#c7ecee",
							];
							const setColor =
								placeholderColors[
									Math.floor(
										Math.random() * placeholderColors.length
									)
								];

							return (
								<InstagramPhoto key={node?.id}>
									<a href={node?.permalink} target="_blank">
										<img
											src={node?.media_url}
											alt="Instagram Photo"
											style={{
												backgroundColor: setColor,
											}}
										/>
									</a>
								</InstagramPhoto>
							);
						})}
					</InstagramWrapper>
				) : (
					""
				)} */}
			</SidebarWidget>
		</SidebarWrapper>
	);
};

export default Sidebar;
