import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { borderRadius } from "styled-system";

export const SidebarWrapper = styled.div`
	margin-top: 20px;
	width: 300px;
	margin-left: 100px;

	@media (max-width: 1239px) {
		width: 300px;
		margin-left: 80px;
	}

	@media (max-width: 1023px) {
		width: 300px;
		margin-left: 60px;
	}

	@media (max-width: 767px) {
		width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	@media (max-width: 424px) {
		width: 350px;
		margin-left: auto;
		margin-right: auto;
	}

	@media (max-width: 374px) {
		width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	/* flex: 0 0 360px;
	max-width: 360px;
	@media (max-width: 1249px) {
		flex: 0 0 320px;
		max-width: 320px;
	}
	@media (max-width: 1100px) {
		flex: 0 0 280px;
		max-width: 280px;
	}

	@media (max-width: 990px) {
		flex: 0 0 220px;
		max-width: 220px;
	}
	@media (max-width: 767px) {
		flex: 0 0 100%;
		max-width: 100%;
		margin-top: 60px;
	} */
`;

export const SidebarWidget = styled.div`
	.author-box {
		text-align: center;
		.avatar {
			border: 2px solid ${themeGet("colors.background")};
			box-shadow: 0 2px 8px rgb(0 0 0 / 10%),
				0 4px 4px -4px rgb(0 0 0 / 10%);
			border-radius: 50%;
		}
		.name {
			margin-top: 1em;
			font-size: 0.8em;
		}
		.author-detail {
			p {
				line-height: 2.2;
			}
			//アンダーライン
			em {
				font-style: normal;
				background: linear-gradient(
					transparent 60%,
					${themeGet("colors.primaryDark")} 0%
				);
			}
			padding-top: 1.5em;
			font-size: 0.8em;
			text-align: left;
		}
	}

	position: relative;
	/* padding: 30px; */
	/* border: 1px solid #e6e6e6; */
	border-radius: 3px;
	margin-bottom: 50px;
	text-align: justify;

	@media (max-width: 1249px) {
		padding: 0px;
	}

	@media (max-width: 1100px) {
		padding: 0px;
	}

	img {
		/* max-width: 100%; */
		/* height: auto; */
	}

	.featured_post {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		.post_preview {
			/* flex: 0 0 70px; */
			margin-right: 20px;
			@media (max-width: 990px) {
				/* flex: 0 0 40px; */
				margin-right: 10px;
			}
		}
		.post_title {
			font-size: 10px;
			font-weight: 400;
			@media (max-width: 990px) {
				font-size: 10px;
				font-weight: 400;
			}
		}
		.post_tags {
			@media (max-width: 990px) {
				display: none;
			}
		}
	}
`;

export const WidgetTitle = styled.h2`
	color: ${themeGet("colors.textColor", "#292929")};
	letter-spacing: 0.17em;
	position: relative;
	margin-bottom: 30px;

	&:after {
		content: "";
		width: 80px;
		height: 1px;
		background: #292929;
		display: block;
		margin-top: 8px;
	}

	@media (min-width: 1240px) {
		font-size: 18px;
		margin-bottom: 20px;
	}

	@media (max-width: 1239px) {
		font-size: 18px;
		margin-bottom: 20px;
	}

	@media (max-width: 767px) {
		font-size: 17px;
		margin-bottom: 20px;
	}

	@media (max-width: 424px) {
		font-size: 16px;
		margin-bottom: 20px;
	}

	@media (max-width: 374px) {
		font-size: 15px;
		margin-bottom: 20px;
	}
`;
export const TagItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 17px;
	font-size: 15px;
	&:last-child {
		margin-bottom: 0;
	}

	> span {
		color: ${themeGet("primary", "#D10068")};
		margin-right: 15px;
	}

	a {
		display: flex;
		align-items: center;
		text-transform: capitalize;
		color: ${themeGet("colors.textColor", "#292929")};
		padding: 5px 0;
		transition: 0.15s ease-in-out;

		> span {
			margin-left: 10px;
		}
		&:hover {
			color: ${themeGet("primary", "#D10068")};
		}
	}
`;

export const InstagramWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 -5px;
`;

export const InstagramPhoto = styled.div`
	flex: 0 0 25%;
	max-width: 25%;
	padding: 0 5px;

	> a {
		border-radius: 3px;
		overflow: hidden;
		display: inline-flex;
		> img {
			transition: 0.15s ease-in-out;
		}
		&:hover {
			> img {
				transform: scale(1.1);
			}
		}
	}
`;
