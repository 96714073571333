import * as React from "react";
import { graphql } from "gatsby";
import PostCardModern from "../components/post-card-modern/post-card-modern";
import Pagination from "../components/pagination/pagination";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Sidebar from "../containers/sidebar";
import { BlogPostsWrapper, PostRow, PostGrid } from "./templates.style";

const BlogList = (props: any) => {
	const Posts = props.data.allWpPost.edges;
	const { currentPage, numPages } = props.pageContext;
	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPage =
		currentPage - 1 === 1
			? "/page/1/"
			: `/page/${(currentPage - 1).toString()}/`;
	const nextPage = `/page/${(currentPage + 1).toString()}/`;
	const PrevLink = !isFirst && prevPage;
	const NextLink = !isLast && nextPage;

	return (
		<Layout>
			<SEO title={`Page ${currentPage}`} />

			<BlogPostsWrapper>
				<PostRow>
					{Posts.map(({ node }: any) => {
						const tags = node.tags.nodes.map((tag: any) => {
							return tag.name;
						});
						// Random Placeholder Color
						const placeholderColors = [
							"#55efc4",
							"#81ecec",
							"#74b9ff",
							"#a29bfe",
							"#ffeaa7",
							"#fab1a0",
							"#e17055",
							"#0984e3",
							"#badc58",
							"#c7ecee",
						];
						const setColor =
							placeholderColors[
								Math.floor(
									Math.random() * placeholderColors.length
								)
							];
						return (
							<PostGrid>
								<PostCardModern
									key={node.uri}
									title={node.title}
									image={
										node.featuredImage == null
											? null
											: node.featuredImage.node.localFile
													.childImageSharp
													.gatsbyImageData
									}
									url={node.uri}
									description={node.excerpt}
									modified={node.modified}
									tags={tags}
									placeholderBG={setColor}
								/>
							</PostGrid>
						);
					})}
				</PostRow>
				<Pagination
					prevLink={PrevLink}
					nextLink={NextLink}
					currentPage={`${currentPage}`}
					totalPage={`${numPages}`}
				/>
			</BlogPostsWrapper>
			<Sidebar />
		</Layout>
	);
};

export default BlogList;

export const pageQuery = graphql`
	query ($skip: Int!, $limit: Int!) {
		site {
			siteMetadata {
				title
			}
		}
		sitePage {
			path
		}
		allWpPost(
			sort: { fields: modified, order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					uri
					title
					excerpt
					modified(formatString: "YYYY年MM月DD日")
					tags {
						nodes {
							name
						}
					}
					featuredImage {
						node {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										sizes: "400"
										placeholder: BLURRED
										layout: FULL_WIDTH
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
			}
		}
	}
`;
