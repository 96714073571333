import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const PostCardModernWrapper = styled.div`
	display: flex;
	height: 100px;
	margin-bottom: 60px;
	width: 400px;
	@media (max-width: 1239px) {
		width: 400px;
		margin-bottom: 50px;
	}
	@media (max-width: 1023px) {
		width: 350px;
		margin-bottom: 50px;
	}
	@media (max-width: 767px) {
		width: 400px;
		margin-bottom: 50px;
	}
	@media (max-width: 424px) {
		width: 350px;
		margin-bottom: 50px;
	}
	@media (max-width: 374px) {
		width: 300px;
		margin-bottom: 40px;
	}
`;

export const PostPreview = styled.div`
	flex-basis: 160px;
	flex-shrink: 0;
	box-shadow: 0 2px 8px rgb(0 0 0 / 10%), 0 4px 4px -4px rgb(0 0 0 / 10%);
	margin-right: 15px;
	img {
		width: 100%;
		height: 100px;
		object-fit: cover;
	}
`;

export const PostDetails = styled.div`
	flex-basis: auto;

	//入子
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const PostTitle = styled.h2`
	color: ${themeGet("colors.textColor", "#292929")};
	text-align: justify;
	/* display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden; */
	line-height: 1.4;
	margin-bottom: 0;
	font-size: 15px;

	@media (max-width: 1239px) {
		font-size: 14px;
	}
	@media (max-width: 424px) {
		font-size: 13px;
	}
	@media (max-width: 374px) {
		font-size: 12px;
	}
	/* a {
		color: ${themeGet("colors.textColor", "#292929")};
		transition: 0.15s ease-in-out;
		&:hover {
			color: ${themeGet("primary", "#D10068")};
		}
	} */
`;

export const PostDate = styled.div`
	/* display: block; */
	/* font-size: ${themeGet("fontSizes.2", "15")}px; */
	color: ${themeGet("colors.baseColorDark")};
	font-weight: 800;
	/* text-transform: uppercase; */
	font-size: 13px;

	@media (max-width: 1239px) {
		font-size: 12px;
	}
	@media (max-width: 424px) {
		font-size: 11px;
	}
	@media (max-width: 374px) {
		font-size: 10px;
	}

	::before {
		margin-right: 0.4em;
		content: "🔁";
	}
`;

export const Excerpt = styled.p`
	/* font-size: ${themeGet("fontSizes.3", "15")}px;
	color: ${themeGet("textColor", "#292929")};
	font-weight: 400;
	line-height: 2;
	margin-bottom: 0;
	@media (max-width: 990px) {
		font-size: 14px;
	} */
`;

export const PostTags = styled.div`
	/* display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 15px;

	a {
		display: block;
		margin-right: 30px;
		font-size: 14px;
		font-weight: 400;
		color: ${themeGet("primary", "#D10068")};
		@media (max-width: 990px) {
			font-size: 13px;
			margin-right: 25px;
		}
	} */
`;
