import * as React from "react";
import { Link } from "gatsby";
import _ from "lodash";
import GatsbyImage from "../gatsby-image";
import {
	PostCardModernWrapper,
	PostPreview,
	PostDetails,
	PostDate,
	PostTitle,
	Excerpt,
	PostTags,
} from "./post-card-modern.style";

interface PostCardModernProps {
	image?: any;
	title: string;
	description?: string;
	url: string;
	modified?: string;
	tags?: [];
	className?: string;
	placeholderBG?: string;
}

const PostCardModern: React.FunctionComponent<PostCardModernProps> = ({
	image,
	title,
	description,
	url,
	modified,
	tags,
	className,
	placeholderBG,
	...props
}) => {
	// Add all classs to an array
	const addAllClasses = ["post_card_modern"];

	// className prop checking
	if (className) {
		addAllClasses.push(className);
	}

	return (
		<Link to={url}>
			<PostCardModernWrapper
				className={addAllClasses.join(" ")}
				{...props}
			>
				{image == null ? null : (
					<PostPreview className="post_preview">
						<GatsbyImage
							src={image}
							alt="post preview"
							backgroundColor={placeholderBG}
						/>
					</PostPreview>
				)}

				<PostDetails className="post_details">
					<PostTitle className="post_title">
						<span>
							{title.length < 50
								? title
								: `${title.slice(0, 49)}…`}
						</span>
					</PostTitle>
					<PostDate>{modified}</PostDate>
				</PostDetails>
			</PostCardModernWrapper>
		</Link>
	);
};

export default PostCardModern;
